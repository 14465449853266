import React, { useState, useEffect, useContext } from 'react';
import { Paper, Icon, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';

import * as Sentry from '@sentry/browser';

import institutionService from '../services/institution';
import AppContext from '../context/app'
import Vimeo from '@u-wave/react-vimeo';

const defaultResourcesCategories = [
  { 
    title: "Get Started", 
    permission: ['userManagement'],
    resources: [
      { type: 'document', title:  "Getting Started", file: '/files/Getting Started.pdf'},
      { type: 'video', title:  "How to Invite Staff to Take the Digital Training Courses",  videoId: 346870619},
      { type: 'document', title:  "How to Invite Teachers and Staff to Take the Digital Training Courses", file: '/files/How to Invite Teachers and Staff to Take the Digital Training Courses.pdf'},
      {type: 'video', title:  "How to Add Authorized Threat Assessment Managers", videoId: 321493327, permission: ['threatTeam','viewAllThreats'], system: 'threatAssessment'}
    ]
  },
  { 
    title: "Threat & Behavioral Assessment",
    system: ['bullyAssessment', 'threatAssessment','assessmentSBTMT'],
    permission: ['threatTeam','viewAllThreats'],
    resources: [
      { type: 'document', title:  "Threat & Behavioral Assessment Software Overview",  file: '/files/Threat and Behavioral Assessment Software Overview.pptx', system: 'threatAssessment'},
      { type: 'document', title:  "How to create a Threat Case", file: '/files/Creating a threat case.pdf'},
      { type: 'video', title:  "How to Use the Threat & Behavioral Assessment Solution", videoId: 400243137, system: 'threatAssessment'},  
      { type: 'video', title:  "How to Use the Threat & Behavioral Assessment HPTM Software", videoId: 975325375, system: 'assessmentSBTMT'},  
      { type: 'document', title:  "HPTM Model Workflow", file: '/files/Threat Management Manual Workflow.pdf', system: 'assessmentSBTMT'},      
      { type: 'document', title:  "HPTM Model Manual", file: '/files/Threat Management Manual.pdf', system: 'assessmentSBTMT'},      
      { type: 'document', title:  "How to reopen a Threat Case and follow up", file: '/files/Reopening threat cases and followup.pdf'},      
      { type: 'document', title:  "How to Resolve and Archive a Threat Case", file: '/files/Resolving and Archiving Threat cases.pdf'},      
      { type: 'video', title:  "How to Use the Suicide Screening Feature", videoId: 400260512, system: 'threatAssessment'},
      { type: 'video', title:  "How to Use the Bullying Intervention Solution",  videoId: 400827636, system: 'bullyAssessment' },
      { type: 'video', title:  "How to Use the Bullying Assessment Feature", videoId: 400257797, system: 'bullyAssessment'}
    ]
  },
  { 
    title: "Emergency Management Plan",
    system: ['emergencyPlan'],
    permission: ['emergencyPlan'],
    resources: [
      { type: 'video', title:  "How to use Emergency Management Plan Software", videoId: 307497454},
      { type: 'video', title:  "How to Update Emergency Management Plan", videoId: 346870693},
      { type: 'document', title:  "How to Update Emergency Management Plan", file: '/files/How to Update the Emergency Management Plan.pdf'},
      { type: 'document', title:  "How to Update Emergency Management Reunification Plan", file: '/files/Accessing Reunification.pdf'}
    ]
  },
  { 
    title: "Security Threat & Vulnerability Assessment",
    system: ['riskSurvey', 'riskSurveyTargeted', 'riskSurveyProtection'],
    permission: ['riskSurvey'],
    resources: [
      { type: 'video', title:  "How to Use the Security Threat & Vulnerability Assessment Solution", system: 'riskSurvey', videoId: 399213450 },
      { type: 'video', title:  "CLPS Risk Protection Order Investigation Software", system: 'riskSurveyProtection', videoId: 804591262 },
      { type: 'video', title:  "CLPS Security Threat & Vulnerability Assessment Software", system: 'riskSurveyTargeted', videoId: 804591240 }
    ]
  },
  { 
    title: "Tap App",
    system: ['tapApp'],
    resources: [
      { type: 'video', title:  "How to use the Back End Dashboard", videoId: 794586383, permission: ['userManagement']},
      { type: 'document', title:  "How to use the Back-end Dashboard", file: '/files/TAP App Security - Back-end Dashboard Training.pptx', permission: ['userManagement']},
      { type: 'document', title:  "How to Register an End-User Account", file: '/files/TAP App Security - How to Register an End-User Account.pdf', permission: ['userManagement']},
      { type: 'video', title: "How to Set TAP App Security to Drill Mode", videoId: 680021603, permission: ['userManagement'] },
      { type: 'video', title: "How to use TAP App on your phone", videoId: 794586673},
      { type: 'document', title: "How to use the TAP App on your phone", file: '/files/TAP App Security - End User Training.pptx'},
      { type: 'video', title:  "How to Share TAP App Alerts with Public Subscribers", videoId: 680009650, permission: ['userManagement']}
    ]
  },
  { 
    title: "Information",
    permission: ['userManagement'],
    resources: [
      { type: 'document', title:  "Lockdown Procedures - Active Shooter", file: '/files/Lockdown Procedures- Active shooter.pptx'},
      { type: 'document', title:  "Privacy Policy", file: '/files/Privacy Policy CLPS Software Solutions.pdf'}
    ]
  }
]


export default function Learning(props) {
  const context = useContext(AppContext)

  const [resourceCategories, setResourceCategories] = useState([])
  const [playVideoId, setPlayVideoId] = useState(null)
  const [videoIdLoading, setVideoIdLoading] = useState(false)

  let fetchData = async () => {
    if (!context.user)
      return

    try {
      let systems = await new institutionService().getInstitutionSystem()
      let viewResourceCategories = defaultResourcesCategories
      viewResourceCategories = viewResourceCategories.filter( (resource) => ( !resource.system || systems.find( (system) => { return resource.system.includes(system.system) })) )
      //role filters
      viewResourceCategories = viewResourceCategories.filter( (resource) => ( !resource.permission || resource.permission.find( p => context.user[p])))
      viewResourceCategories.forEach( (resourceCategory) => {
        resourceCategory.resources = resourceCategory.resources.filter( r => !r.system || systems.find( (system) => system.system === r.system ))
        resourceCategory.resources = resourceCategory.resources.filter( r => !r.permission || r.permission.find( p => context.user[p]))
      }) 
      setResourceCategories(viewResourceCategories)
    }
    catch(err){
      console.log(err)
      context.toastError('Error loading')
      Sentry.captureException(err);
    }
  }

  useEffect( () => {
    fetchData();
  },[])

  useEffect( () => {
    fetchData();
  },[context.user])
 
  let handleVideoLoad = (videoId) => {
    setVideoIdLoading(true)
    setTimeout( () => {    
      setPlayVideoId(videoId)
      setVideoIdLoading(false)
    }
    , 200  )    
  }

  if (!context.user)
    return null;

  return (
      <div id='learning'>
        <Dialog className="learning" fullWidth={true} open={!videoIdLoading && playVideoId} onClose={() => setPlayVideoId(null) }>
            <Vimeo className="vimeo-video" video={ playVideoId } autoplay={true} />
        </Dialog>

        <Paper  >
          { resourceCategories.map( (resourceCategory, index) => {
            return (
              <div key={index} className="resourceCategory">
                  <h2>{resourceCategory.title}</h2>
                    <div className="resources flex-row">
                      <div className="flex-col">
                        {resourceCategory.resources.map( (resource, index) => {
                          return (
                            <div key={index} className="resource flex-row">
                                <div className="flex-col">
                                  { resource.type === 'video' ?
                                    <a className="videoLink" rel="noopener noreferrer" onClick={ (e) => { handleVideoLoad(resource.videoId) } }><i className="fa fa-video"></i> {resource.title}</a>
                                  : null }
                                  { resource.type === 'document' ?
                                    <a download className='documentLink' rel="noopener noreferrer" target="_blank" href={resource.file}><i className="fa fa-file"></i> {resource.title}</a>
                                  : null }
                                </div>
                            </div>
                          )})
                        }
                      </div>
                    </div>
              </div>
            )})
          }
        </Paper>


        <div className='actions'>
          <Button onClick={(e) => window.history.back()} variant="outlined"><Icon className="fa fa-arrow-left" />Back</Button>
        </div>


        
      </div>
  )
}